import Box from '@amzn/awsui-components-react/polaris/box';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';

export default function ErrorPageUnathorized() {
  return (
    <div id="error-page" style={{ minHeight: '100vh' }}>
      <Box>
        <SpaceBetween direction="vertical" alignItems="center" size="xl">
          <Header variant="h2">Ooops</Header>
          <Header variant="h3">
            It appears you are not authorized to see this page. If this is an
            error, please reach out to your TOpS.
          </Header>
        </SpaceBetween>
      </Box>
    </div>
  );
}
