import React from 'react';
import Link from '@amzn/awsui-components-react/polaris/link';
import { colorBackgroundLayoutMain } from '@amzn/awsui-design-tokens/polaris';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Box from '@amzn/awsui-components-react/polaris/box';

export const PageFooter = () => {
  return (
    <div
      data-testid="Test_Footer"
      style={{
        // to center sub div with grid in it
        paddingBottom: '2rem',
        width: '100%',
        backgroundColor: colorBackgroundLayoutMain,
      }}
    >
      <div
        style={{
          width: 'max-content', // keeps grid centered nicely based on content
          margin: '0 auto',
          maxWidth: '100%', // needed to keep grid from overflowing
        }}
      >
        <Grid
          disableGutters
          gridDefinition={[
            { colspan: { default: 2 } },
            { colspan: { default: 1 } },
            { colspan: { default: 2 } },
            { colspan: { default: 1 } },
            { colspan: { default: 6 } },
          ]}
        >
          <Link
            variant="secondary"
            href="https://aws.amazon.com/privacy/?src=aws_training"
            data-testid="Footer_Privacy"
          >
            <Box variant="p" color="inherit" textAlign="center">
              Privacy
            </Box>
          </Link>
          <Box variant="p" textAlign="center">
            |
          </Box>
          <Link
            variant="secondary"
            href="https://aws.amazon.com/terms/?nc1=f_pr"
            data-testid="Footer_SiteTerms"
          >
            <Box variant="p" color="inherit" textAlign="center">
              Site terms
            </Box>
          </Link>
          <Box variant="p" textAlign="center">
            |
          </Box>
          <Box data-testid="Footer_Text" variant="p" textAlign="center">
            © 2023, Amazon Web Services, Inc. or its affiliates. All rights
            reserved.
          </Box>
        </Grid>
      </div>
    </div>
  );
};
