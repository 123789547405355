import Box from '@amzn/awsui-components-react/polaris/box';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { colorBackgroundLayoutMain } from '@amzn/awsui-design-tokens/polaris';
import React, { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import ReactLoading from 'react-loading';
import { Banner } from '../components/Banner';

/*
If userContext.isLoading is true, we do not yet know if a user is signed in.
In this case, leave a loading circle so that the user can see that the page is
loading.  Once we know that user context has loaded, then load the intended
landing page content.
*/
export const LandingPage = () => {
  const userContext = useContext(UserContext);
  return (
    <>
      <div
        style={{
          minHeight: '100vh',
          backgroundColor: colorBackgroundLayoutMain,
        }}
      >
        <Banner />
        {userContext.isLoading === true && (
          <div style={{ padding: '2rem' }}>
            <ReactLoading color="black" />
          </div>
        )}
        {userContext.isLoading === false && (
          <Box>
            <SpaceBetween direction="vertical" alignItems="center" size="xl">
              <Header variant="h2">
                Welcome to Customer Communication Hub!
              </Header>
              <Header variant="h3">Please login to see your dashboard.</Header>
            </SpaceBetween>
          </Box>
        )}
      </div>
    </>
  );
};
