import { useRouteError } from 'react-router-dom';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';

export default function ErrorPage() {
  const error = useRouteError();
  console.debug(error);
  return (
    <div id="error-page" style={{ minHeight: '100vh' }}>
      <Box>
        <SpaceBetween direction="vertical" alignItems="center" size="xl">
          <Header variant="h2">Ooops</Header>
          <Header variant="h3">Sorry, an unexpected error has occurred.</Header>
        </SpaceBetween>
      </Box>
    </div>
  );
}
