import React, { useContext } from 'react';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import { UserContext } from '../contexts/UserContext';
import { handleSignIn, handleSignOut } from '../utils/authHelper';

export const PageHeader = () => {
  const userContext = useContext(UserContext);

  const outerCenteringDivBaseStyle: any = {
    // to center child components in navbar.  Also sets height of navbar.
    width: '100%',
    height: '85px',
    display: 'flex',
  };

  return (
    <div
      data-testid="Test_Header"
      style={{
        position: 'sticky',
        width: '100%',
        top: '0',
        zIndex: 2000, // suggested: https://cloudscape.aws.dev/get-started/dev-guides/z-index/#ranges
        height: 'max-content', // set to height of child components
      }}
    >
      <Container
        disableContentPaddings
        media={{
          content: (
            <div
              style={{
                ...outerCenteringDivBaseStyle,
                alignItems: 'center',
              }}
            >
              <img
                data-testid="Test_Logo"
                // apple needs SVG to have width and height specified
                style={{ padding: '1rem', width: '12rem', height: 'auto' }}
                src="/aws-tc-logo.svg"
                alt="Training & Certification Logo"
              />
            </div>
          ),
          position: 'side',
          width: '70%',
        }}
      >
        <div
          style={{
            ...outerCenteringDivBaseStyle,
            justifyContent: 'right',
          }}
        >
          <div
            style={{
              // center vertically and set margin right to 1 rem
              width: 'max-content',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              marginRight: '1rem',
            }}
          >
            {!userContext.gandalfUserData ? (
              <Button
                data-testid="Test_Login"
                variant="primary"
                onClick={handleSignIn}
                wrapText={false}
              >
                Sign In
              </Button>
            ) : (
              <Button
                data-testid="Test_Logout"
                onClick={handleSignOut}
                wrapText={false}
              >
                Sign Out
              </Button>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};
