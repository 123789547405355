import React from 'react';
import { ReactNode } from 'react';
import { unmarshalRedirectState } from './redirectUtil';

export type RedirectBoundaryParams = {
  children: ReactNode;
  /**
   * Get the state, defaults to the state parameter
   */
  getState?: () => string | null;
  /**
   * Parses the state, defaults to decoding the state using Amplify's specs
   */
  parseState?: (state: string) => string;
  /**
   * Gets the stored nonce, defaults to looking in the nonce cookie
   */
  getNonce?: () => string | null;
  /**
   * Gets the auth code, defaults to the code param
   */
  getCode?: () => string | null;
  /**
   * Follow the redirect URL, defaults to window.open(redirectUrl, _self)
   */
  followRedirect?: (redirectUrl: string) => void;
};

/**
 * This serves to wrap an application and check if the state parameter matches the schema for a redirect state. This is an optional
 * inclusion to any application, and is only really useful if you want to redirect the user to a vanity URL. The implementation here
 * relies on setting the redirect url on authentication to the current page (which could be a vanity url) encrypted with a nonce to
 * ensure the redirect url was set by this session. In this example there is no manual allowlisting of the URL since it's assumed to
 * be safe, however in a production context it would be reccomended to do so to prevent any edge cases.
 *
 * @param options
 * @returns RedirectBoundary Component
 */
export const RedirectBoundary = ({
  children,
  getState = () => new URLSearchParams(window.location.search).get('state'),
  // Amplify v3.4.29 encodes the custom state provided. Future Amplify versions have removed this.
  // Remove urlSafeDecode if your Amplify is newer
  // Additionally, Amplify adds in a - between its randomly generated state and the custom state.
  parseState = (state: string) =>
    decodeURIComponent(state).split('-').slice(1).join('-'),
  getNonce,
  getCode = () => new URLSearchParams(window.location.search).get('code'),
  followRedirect = (redirectUrl) =>
    window.open(`${redirectUrl}${window.location.search}`),
}: RedirectBoundaryParams) => {
  const rawState = getState();
  const code = getCode();
  const nonce = getNonce ? getNonce() : undefined;
  const state = rawState ? parseState(rawState) : undefined;
  const redirectState = unmarshalRedirectState(state, nonce);
  // The goal of the redirection boundary here is for post-auth flow which involve a code, so the code must be present.
  if (redirectState && code) {
    // This ensures that there can't be a redirect loop
    if (redirectState.redirectUrl !== window.location.href.split('?')[0]) {
      // This will redirect to the url passing through the code.
      followRedirect(redirectState.redirectUrl);
      return <></>;
    }
  }

  return <>{children}</>;
};
