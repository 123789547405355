import Flashbar, {
  FlashbarProps,
} from '@amzn/awsui-components-react/polaris/flashbar';

/*
This will display a banner containing bannerItems in a non-sticky banner.


Docs: https://v2-1.polaris.a2z.com/components/awsui-flashbar/?example=info


NOTE: dismissible does not appear to work as expected.
*/
export const Banner = () => {
  const bannerItems = [
    {
      type: 'info',
      dismissible: false,
      content:
        'Welcome to the new Customer Communication Hub experience! This will be the only way to access the dashboard after Dec 1st 2024, please migrate to this new experience before then and reach out to your supporting AWS Training Operations Specialist or Customer Success Manager with any further questions. Make sure to bookmark this page for easy access!',
    },
  ] as ReadonlyArray<FlashbarProps.MessageDefinition>;
  return <Flashbar items={bannerItems}></Flashbar>;
};
