import React, { useContext } from 'react';
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import { PageHeader } from './components/PageHeader';
import { PageFooter } from './components/PageFooter';
import { colorBackgroundLayoutMain } from '@amzn/awsui-design-tokens/polaris';
import { Outlet, Navigate } from 'react-router-dom';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './pages/ErrorPage';
import { LandingPage } from './pages/LandingPage';
import { UserContext } from './contexts/UserContext';
import { LearnerAdminDashboardPage } from './pages/LearnerAdminDashboardPage';
import { TopsDashboardPage } from './pages/TopsDashboardPage';
import ErrorPageUnathorized from './pages/ErrorPageUnauthorized';

// const getUserAttr = (user: any, attr: string) =>
//   user?.signInUserSession?.idToken?.payload?.[attr];

// To make it easier to see all usage flows all logic has been put into one component.
const App = () => {
  const userContext = useContext(UserContext);

  // TO DO: This function should be refactored and abstracted to an element that
  // can be imported into different components at some point.
  const redirectAuthorization = (cchAuthorization: string | null) => {
    let element: any;
    switch (cchAuthorization) {
      case null:
        element = <Navigate to="/" replace={true} />;
        break;
      case 'learner_admin':
        element = <Navigate to="/LearnerAdmin" replace={true} />;
        break;
      case 'tops':
        element = <Navigate to="/TOpS" replace={true} />;
        break;
      default:
        element = <Navigate to="/error" replace={true} />;
        break;
    }
    return element;
  };

  // TO DO: this component should be broken out to another file and cleaned up
  const PageLayout = () => {
    return (
      <ContentLayout>
        <div
          style={{ backgroundColor: colorBackgroundLayoutMain, width: '100%' }}
        >
          <PageHeader></PageHeader>
          {/* <h2>
            Welcome,{' '}
            {getUserAttr(userContext.gandalfUserData, 'given_name') || '...'}
          </h2>
          <p>{JSON.stringify(userContext)}</p> */}
          {userContext.isLoading === false &&
            redirectAuthorization(userContext.cchAuthorization)}
          <Outlet />
          <PageFooter></PageFooter>
        </div>
      </ContentLayout>
    );
  };

  // TO DO: put routers in separate location
  const router = createBrowserRouter([
    {
      path: '/',
      element: <PageLayout></PageLayout>,
      errorElement: <ErrorPage />,
      children: [
        {
          path: '/',
          element: <LandingPage></LandingPage>,
          errorElement: <ErrorPage />,
        },
        {
          path: '/error',
          element: <ErrorPageUnathorized />,
          errorElement: <ErrorPage />,
        },
        {
          path: '/LearnerAdmin',
          element: <LearnerAdminDashboardPage></LearnerAdminDashboardPage>,
          errorElement: <ErrorPage />,
        },
        {
          path: '/TOpS',
          element: <TopsDashboardPage></TopsDashboardPage>,
          errorElement: <ErrorPage />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
