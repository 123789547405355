import React, { useEffect } from 'react';

import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import { useQuery } from 'react-query';
import { callGenerateDashboardURL } from '../utils/apiHelper';

type dashboardUserTypes = 'test' | 'learnerAdmin' | 'tops';
type dashboardIds = 'cch_sso_main_dashboard' | 'cch_sso_test_dashboard';

type DashboardProps = {
  dashboardUserType: dashboardUserTypes;
  dashboardId: dashboardIds;
  jwtToken: string;
  setDashboardLoaded: Function;
};

// To make it easier to see all usage flows all logic has been put into one component.
const Dashboard = (props: DashboardProps) => {
  const { dashboardUserType, dashboardId, jwtToken, setDashboardLoaded } =
    props;
  // console.log('rendering Dashboard'); // to see if api call repeated

  const { isLoading, error, data } = useQuery(['dashboard', jwtToken], () =>
    callGenerateDashboardURL(dashboardUserType, dashboardId, jwtToken)
  );

  // console.log('rendering TestDashboard data', data); // to see if api call repeated
  // if (data) console.log('rendering TestDashboard data', data['EmbedUrl']); // to see if api call repeated

  useEffect(() => {
    if (data) {
      const url = data['EmbedUrl'];
      embedDashboard({
        url: url,
        container: document.getElementById('embeddingContainer') as HTMLElement,
        scrolling: 'no',
        iframeResizeOnSheetChange: true, // use this option in combination with height: AutoFit, to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
        width: '100%',
        height: 'AutoFit',
        locale: 'en-US',
        // footerPaddingEnabled: false,
        // selectedSheetChangeCallback: analyticsSheetChangeCB,
        // parametersChangeCallback: analyticsParametersChangeCB,
      });
      setDashboardLoaded(true);
    }
  }, [data, setDashboardLoaded]);

  if (isLoading) {
    return null;
  }
  if (error) {
    // console.log(error);
    return null;
  }

  return (
    <div
      style={{
        height: '100%',
        width: '95%',
        margin: '0 auto',
      }}
      id="embeddingContainer"
    ></div>
  );
};

export default Dashboard;
