/*
This function gets the appropriate api domain for the environment this website
is deployed in based on the window.location.hostname.
*/
export function getApiDomain() {
  if (window.location.hostname === 'localhost') {
    return `https://api.customer-communication-hub.alpha.atlas.training.aws.dev`;
  }
  return `https://api.${window.location.hostname}`;
}

/*
This function creates a post request to generate a dashboard url.  It requires
`dashboardUserType` which is either 'LearnerAdmin' or 'TOpS', dashboardId which
is the quicksight dashboard ID to be embedded and the jwtToken of the current
user session.  A successful response will contain an `EmbedUrl` which contains
the url to load a dashboard.
*/
export async function callGenerateDashboardURL(
  dashboardUserType: string,
  dashboardId: string,
  jwtToken: string
) {
  const apiDomain = getApiDomain();
  const response = await fetch(
    `${apiDomain}/v1/generateDashboard/${dashboardUserType}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ dashboardId: dashboardId }),
    }
  );
  const json_response: any = await response.json();
  return json_response;
}
