import { Auth } from 'aws-amplify';
import { generateRedirectState } from '../redirectUtil';
import {
  CognitoUser,
  CognitoHostedUIIdentityProvider,
} from '@aws-amplify/auth';

// throw an error if the user is not logged in
export const getLoggedInUser = async (): Promise<CognitoUser | any> => {
  const user = await Auth.currentAuthenticatedUser();
  return user;
};

export const handleSignIn = () =>
  Auth.federatedSignIn({
    // If you want to use ESSO with a sepcific provider, you can change this here (ex use case for vanity urls)
    provider: CognitoHostedUIIdentityProvider.Cognito,
    customState: generateRedirectState({}),
  });

export const handleSignOut = () => {
  Auth.signOut().catch((error) => {
    console.debug('Issue signing out', error);
  });
};
