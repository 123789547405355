import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './styles.css';

import { RedirectBoundary } from './RedirectBoundary';
import { UserContextProvider } from './contexts/UserContext';
import { QueryClient, QueryClientProvider } from 'react-query';

const tenHoursInMs = 1000 * 60 * 60 * 10;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // don't refetch component when ui clicked on
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: tenHoursInMs, // length of quicksight dashboard session, don't re-fetch for 10 hours
      cacheTime: 0, // since url can only be rendered 1x, don't cache url
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    {/* Obs. Putting the boundary for redirection before the app rendering prevents token exchange */}
    <RedirectBoundary>
      <QueryClientProvider client={queryClient}>
        <UserContextProvider children={<App />}></UserContextProvider>
      </QueryClientProvider>
    </RedirectBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
