import { Auth } from 'aws-amplify';
import config from './config.json';
import { MemoryStorage } from '@aws-amplify/core';

function isLocalhost() {
  return window.location.hostname === 'localhost';
}

/* Get stage based on current domain name*/
function getStage() {
  switch (window.location.hostname.toLowerCase()) {
    case 'customer-communication-hub.alpha.atlas.training.aws.dev':
      return 'alpha';
    case 'customer-communication-hub.beta.atlas.training.aws.dev':
      return 'beta';
    case 'customer-communication-hub.gamma.atlas.training.aws.dev':
      return 'gamma';
    case 'customer-communication-hub.aws.training':
      return 'prod';
    default:
      return 'alpha';
  }
}

// Enable Amplify debugging for better insights when doing local dev.
(window as any).LOG_LEVEL = isLocalhost() ? 'DEBUG' : 'INFO';

// Creates a urlOpener function to intercept sign in calls and change the url.
// See docs, https://aws-amplify.github.io/amplify-js/api/interfaces/awscognitooauthopts.html
const createUrlInterceptor =
  (domain: string, signInUIDomain: string, signOutUIDomain: string) =>
  (url: string) => {
    const SELF = '_self';
    const loginRequestUrl = `https://${domain}/oauth2/authorize`;
    const logoutRequestUrl = `https://${domain}/logout`;
    let windowProxy;

    if (signInUIDomain && url.startsWith(loginRequestUrl)) {
      const signInUrl = url.replace(loginRequestUrl, signInUIDomain);
      windowProxy = window.open(signInUrl, SELF);
    } else if (signInUIDomain && url.startsWith(logoutRequestUrl)) {
      const signOutUrl = url.replace(logoutRequestUrl, signOutUIDomain);
      windowProxy = window.open(signOutUrl, SELF);
    } else {
      windowProxy = window.open(url, SELF);
    }
    return windowProxy ? Promise.resolve(windowProxy) : Promise.reject();
  };

function getRedirectSignInAndOutUrl(config: any): string | null {
  if (isLocalhost()) {
    return config.Gandalf.localhostRedirectSignInAndOut;
  }

  if (config.Auth.oauth.allowListDomains.includes(window.location.hostname)) {
    return `https://${window.location.hostname}/`;
  }

  return null;
}

const configureAmplify = () => {
  const signInAndOutUrl = getRedirectSignInAndOutUrl(config);
  const stage = getStage();
  return Auth.configure({
    Auth: {
      // The use of MemoryStorage is crucial here.
      // By default Amplify stores Gandalf tokens
      // in LocalStorage, which will result in an AppSec finding
      // if your team does not configure Amplify otherwise.
      storage: MemoryStorage,
      region: config.Auth.region,
      userPoolId: config.Auth.userPoolId,
      userPoolWebClientId:
        stage === 'prod'
          ? config.Auth.prodUserPoolWebClientId
          : config.Auth.devUserPoolWebClientId,
      oauth: {
        domain: config.Auth.oauth.domain,
        scope: config.Auth.oauth.scope,
        responseType: config.Auth.oauth.responseType,
        redirectSignIn: signInAndOutUrl,
        redirectSignOut: signInAndOutUrl,
        // (optional) This is used to intercept login requests to navigate to the Custom SignIn UI.
        ...(Boolean(config.useCustomSignInUI) && {
          urlOpener: createUrlInterceptor(
            config.Auth.oauth.domain,
            config.Gandalf.customSignInEndpoint,
            config.Gandalf.customSignOutEndpoint
          ),
        }),
      },
    },
  });
};

export default configureAmplify;
